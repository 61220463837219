<div class="flex-1 flex justify-center flex-wrap" *ngIf="statistics$ | async as statistics">
    <div class="rounded bg-vuturiaGray1 p-2 flex-1 m-1">
        <div class="flex justify-around items-center h-full">
            <div class="text-center mx-2">
                <h3 class="text-xs lg:text-base whitespace-nowrap" translate="profile.pages.statistics.games-played"></h3>
                <p class="text-2xl lg:text-4xl mt-2 font-black text-vuturiaBlue1">{{statistics.gamesPlayed}}</p>
            </div>
            <div class="text-center mx-2">
                <h3 class="text-xs lg:text-base whitespace-nowrap" translate="profile.pages.statistics.winrate"></h3>
                <p class="text-2xl lg:text-4xl mt-2 font-black text-vuturiaBlue1">{{statistics.winrate}}%</p>
            </div>
        </div>
    </div>
    <div class="rounded bg-vuturiaGray1 p-2 flex-1 m-1">
        <div class="flex justify-around items-center h-full">
            <div class="text-center mx-2">
                <h3 class="text-xs lg:text-base whitespace-nowrap" translate="profile.pages.statistics.total-kills"></h3>
                <p class="text-2xl lg:text-4xl mt-2 font-black text-vuturiaBlue1">{{statistics.overallKills}}</p>
            </div>
            <div class="text-center mx-2">
                <h3 class="text-xs lg:text-base whitespace-nowrap" translate="profile.pages.statistics.total-deaths"></h3>
                <p class="text-2xl lg:text-4xl mt-2 font-black text-vuturiaRed3">{{statistics.overallDeaths}}</p>
            </div>
        </div>
    </div>
    <div class="rounded bg-vuturiaGray1 p-2 flex-1 m-1">
        <div class="flex justify-around items-center h-full">
            <div class="text-center mx-2">
                <h3 class="text-xs lg:text-base whitespace-nowrap" translate="profile.pages.statistics.kda"></h3>
                <p class="text-2xl lg:text-4xl mt-2 font-black text-vuturiaPurple3">{{statistics.kda.toFixed(2)}}</p>
            </div>
        </div>
    </div>
    <div class="rounded bg-vuturiaGray1 p-2 flex-1 m-1">
        <div class="flex justify-around items-center h-full">
            <div class="text-center mx-2">
                <h3 class="text-xs lg:text-base whitespace-nowrap" translate="profile.pages.statistics.games-won"></h3>
                <p class="text-2xl lg:text-4xl mt-2 font-black text-vuturiaBlue1">{{statistics.gamesWon}}</p>
            </div>
            <div class="text-center mx-2">
                <h3 class="text-xs lg:text-base whitespace-nowrap" translate="profile.pages.statistics.games-lost"></h3>
                <p class="text-2xl lg:text-4xl mt-2 font-black text-vuturiaRed3">{{statistics.gamesLost}}</p>
            </div>
        </div>
    </div>
</div>

<h3 class="text-center text-xl font-bold">Pirates</h3>
<div class="flex flex-wrap justify-between flex-grow">
    @for (stat of statisticStore.statistics(); track $index) {
        <app-stat-display class="grow" [name]="stat.name" [value]="stat.value"></app-stat-display>
    }
</div>
