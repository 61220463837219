import { Injectable } from "@angular/core";
import { Apollo, gql } from "apollo-angular";
import { Statistic } from "../domain/statistic";
import { map } from "rxjs";

@Injectable({providedIn: "root"})
export class StatisticsClient {

    constructor(private apollo: Apollo){}

    load(userId: string) {
        return this.apollo.query<{statistics: Statistic[]}>({
            query: userStatisticsQuery,
            variables: {
                userId
            }
        }).pipe(map(resp => resp.data.statistics));
    }
}


const userStatisticsQuery = gql `
    query statistics($userId: UUID!) {
        statistics(playerId: $userId){
            name
            value
            applicationId
        }
    }
`;