import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectProfile, selectUserStatistics } from '../../../state/user/user.selector';
import { TranslateModule } from '@ngx-translate/core';
import { StatisticsStore } from './store/statistics.store';
import { ActivatedRoute } from '@angular/router';
import { filter, map, tap } from 'rxjs';
import { StatDisplayComponent } from '../../../components/stat-display/stat-display.component';

@Component({
  selector: 'app-statistics',
  standalone: true,
  imports: [
    CommonModule, TranslateModule, StatDisplayComponent
  ],
  providers: [StatisticsStore],
  templateUrl: './statistics.component.html',
  styleUrl: './statistics.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatisticsComponent implements OnInit{ 

  statisticStore = inject(StatisticsStore);

  statistics$ = this.store.select(selectUserStatistics);

  constructor(private store: Store, private route: ActivatedRoute){}

  ngOnInit(): void {
    this.route.parent?.params
      .pipe(
        map(p => p["id"]),
        filter(id => id))
      .subscribe(id => this.statisticStore.load(id));
  }

}
