<nav [@openClose]="(navbarExpanded$ |async) ? 'nav-open' : 'nav-closed'" class="sidenav w-full lg:shadow-md p-12 bg-vuturiaGray flex items-center justify-center flex-col gap-5 z-10">
    <div class="hover:cursor-pointer" routerLink="/visit">Dein Besuch</div>
    <div class="hover:cursor-pointer" routerLink="/leaderboard">Rangliste</div>
    <div class="grow"></div>
    <div class="hover:cursor-pointer" routerLink="/settings">Settings</div>
    <div class="hover:cursor-pointer" *ngIf="isLoggedIn$ | async; else loginTemplate" (click)="logout()">Logout</div>
</nav>

<ng-template #loginTemplate>
    <div class="hover:cursor-pointer"  (click)="login()">Login</div>
</ng-template>