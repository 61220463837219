import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom, inject, isDevMode, runInInjectionContext } from '@angular/core';
import { provideRouter } from '@angular/router';
import { provideAnimations } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { routes } from './app.routes';
import { Store, provideState, provideStore } from '@ngrx/store';
import { HttpClient, provideHttpClient, withInterceptors } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { RouterState, provideRouterStore, routerReducer } from '@ngrx/router-store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { LanguageActions } from './state/language/language.actions';
import { provideEffects } from '@ngrx/effects';
import { LanguageEffects } from './state/language/language.effects';
import { languageReducer } from './state/language/language.reducer';
import { newsReducer } from './state/news/news.reducer';
import { NewsEffects } from './state/news/news.effects';
import { NewsActions } from './state/news/news.actions';
import { AuthService, authHttpInterceptorFn, provideAuth0 } from '@auth0/auth0-angular';
import { UserEffects } from './state/user/user.effects';
import { userReducer } from './state/user/user.reducer';


import config from '../../capacitor.config';
import { environment } from '../environment';
import { layoutReducer } from './state/layout/layout.reducer';
import { provideServiceWorker } from '@angular/service-worker';
import { LOAD_WASM } from 'ngx-scanner-qrcode';
import { AuthenticationEffects } from './state/authentication/authentication.effects';
import { authActions } from './state/authentication/authentication.actions';
import { authReducer } from './state/authentication/authentication.reducer';
import { RootState } from './state/root.state';
import { LayoutEffects } from './state/layout/layout.effects';
import { NavigationEffects } from './state/navigation/navigation.effects';

import { OneSignal, OnesignalNgxModule } from 'onesignal-ngx';
import { PlayEffects } from './state/play/play.effects';
import { playReducer } from './state/play/play.reducer';
import { provideToastr } from 'ngx-toastr';
import { catchError, firstValueFrom, of, timeout } from 'rxjs';
import { UserClient } from './data/user/user.client';
import {OverlayModule} from '@angular/cdk/overlay'; 
import { Capacitor } from '@capacitor/core';
import { provideApollo } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { InMemoryCache } from '@apollo/client/core';


export function createTranslateLoader(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
}

const redirect_uri =  Capacitor.getPlatform() != 'web' ? `${config.appId}://vuturia.eu.auth0.com/capacitor/${config.appId}/callback` : window.location.origin;

// Necessary to solve the problem of losing internet connection
LOAD_WASM().subscribe();

export function initializeAppFactory(store$: Store, auth: AuthService, userClient: UserClient){
  return async () => {

    try{
      await auth.getAccessTokenSilently();
    }catch(e){
      console.log(e);
    }
    //Loading current userState
    const user = await firstValueFrom(auth.user$.pipe(timeout(1000), catchError(e => of(null))));    
    const profile = user ? await firstValueFrom(userClient.getMe().pipe(timeout(5000), catchError(e => of(null)))) : null;
    
    store$.dispatch(authActions.setLoginState({user: user ?? undefined, profile: profile ?? undefined}));

    store$.dispatch(LanguageActions.beginLoadLanguage());
    store$.dispatch(NewsActions.beginLoadNews());
  }
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideStore<RootState>({
        language: languageReducer,
        news: newsReducer,
        user: userReducer,
        router: routerReducer,
        layout: layoutReducer,
        authentication: authReducer,
        play: playReducer
    }),
    provideEffects(LanguageEffects, NewsEffects, UserEffects, AuthenticationEffects, LayoutEffects, NavigationEffects, PlayEffects),
    provideRouterStore({
        routerState: RouterState.Minimal
    }),
    provideStoreDevtools({ maxAge: 25, logOnly: !isDevMode() }),
    provideHttpClient(withInterceptors([authHttpInterceptorFn])),
    provideAnimations(),
    provideAuth0({
        domain: 'vuturia.eu.auth0.com',
        clientId: environment.auth0.clientId,
        useRefreshTokens: true,
        useRefreshTokensFallback: true,
        cacheLocation: 'localstorage',
        authorizationParams: {
            redirect_uri: redirect_uri,
            audience: environment.auth0.audience,
            scope: 'openid offline_access read:arcade read:users read:games read:visits',
        },
        httpInterceptor: {
            allowedList: [
                {
                  uri: environment.vuturiaApi.baseUrl + '/api/*',
                  tokenOptions: {
                    authorizationParams: {
                      // The attached token should target this audience
                      audience: environment.auth0.audience,
                      scope: 'offline_access read:arcade read:users read:games read:visits',
                      redirect_uri: redirect_uri
                    }
                  }
                },
                {
                  uri: environment.vuturiaApi.baseUrl + '/graphql',
                  tokenOptions: {
                    authorizationParams: {
                      // The attached token should target this audience
                      audience: environment.auth0.audience,
                      scope: 'offline_access read:arcade read:users read:games read:visits',
                      redirect_uri: redirect_uri
                    }
                  }
                }
              ]
        }
    }),
    provideToastr(),
    importProvidersFrom(OverlayModule),
    importProvidersFrom(TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: createTranslateLoader,
            deps: [HttpClient]
        },
        defaultLanguage: 'de'
    })),
    {
      provide: OneSignal,
      useClass: OneSignal
    },
    {
        provide: APP_INITIALIZER,
        useFactory: initializeAppFactory,
        multi: true,
        deps: [Store, AuthService, UserClient, HttpClient, OneSignal]
    },
    provideServiceWorker('ngsw-worker.js', {
        enabled: !isDevMode(),
        registrationStrategy: 'registerWhenStable:30000'
    }), provideHttpClient(), provideApollo(() => {
      const httpLink = inject(HttpLink);

      return {
        link: httpLink.create({
          uri: `${environment.vuturiaApi.baseUrl}/graphql`,
        }),
        cache: new InMemoryCache({
          resultCaching: false,
          typePolicies: {
            GenericPlayer: {
              keyFields: false
            }
          }
        }),
      };
    })
]
};


