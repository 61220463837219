import { patchState, signalStore, withMethods, withState } from "@ngrx/signals"
import { StatisticsClient } from "../clients/statistics.client"
import { inject } from "@angular/core"
import { Statistic } from "../domain/statistic"

type ProfileStatisticsState = {
    loading: boolean,
    statistics: Statistic[]
}

const initialState: ProfileStatisticsState = {
    loading: false,
    statistics: []
}

export const StatisticsStore = signalStore(
    withState(initialState),
    withMethods((store, client = inject(StatisticsClient)) => ({
        load: (userId: string) => {
            patchState(store, {loading: true, statistics: []});
            client.load(userId).subscribe(resp => {
                patchState(store, {loading: false, statistics: resp})
            });
        }
    }))
)