import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, Routes, UrlTree } from '@angular/router';
import { NewsComponent } from './features/news/news.component';
import { PlayComponent } from './features/play/play.component';
import { ProfileComponent } from './features/profile/profile.component';
import { GameHistoryComponent } from './features/profile/game-history/game-history.component';
import { FriendsComponent } from './features/profile/friends/friends.component';
import { NewsDetailComponent } from './features/news/news-detail/news-detail.component';
import { LoginComponent } from './features/login/login/login.component';
import { inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { firstValueFrom, map } from 'rxjs';
import { RegistrationComponent } from './features/login/registration/registration.component';
import { LinkComponent } from './features/login/link/link.component';
import { StartComponent } from './features/login/start/start.component';
import { selectSignedInProfile, selectUser } from './state/authentication/authentication.selector';
import { StatisticsComponent } from './features/profile/statistics/statistics.component';
import { SettingsComponent } from './features/settings/settings.component';
import { VisitOverviewComponent } from './features/visits/visit-overview/visit-overview.component';
import { VisitDetailComponent } from './features/visits/visit-detail/visit-detail.component';

const isLoggedIn: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const store$ = inject(Store);
    const router = inject(Router);
    return store$.select(selectUser)
        .pipe(map(user => {
            return !!user ? true : router.parseUrl('/login')
        }));
}

const hasProfile: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const store$ = inject(Store);
    const router = inject(Router);
    return store$.select(selectSignedInProfile)
        .pipe(map(profile => {
            return !!profile ? true : router.parseUrl('/login/registration')
        }));
}

export const routes: Routes = [
    {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
    },
    {
        path: 'home',
        component: NewsComponent
    },
    {
        path: 'news/:slug',
        component: NewsDetailComponent
    },
    {
        path: 'login',
        component: LoginComponent,
    },
    {
        path: 'login/registration',
        component: RegistrationComponent,
        canActivate: [isLoggedIn]
    },
    {
        path: 'login/link',
        component: LinkComponent,
        canActivate: [isLoggedIn]
    },
    {
        path: 'play',
        component: PlayComponent,
        canActivate: [isLoggedIn, hasProfile]
    },
    {
        path: 'profile/:id',
        component: ProfileComponent,
        canActivate: [isLoggedIn, hasProfile],
        canActivateChild: [isLoggedIn, hasProfile],
        children: [
            {
                path: '',
                redirectTo: 'statistics',
                pathMatch: 'full'
            },
            {
                path: 'games',
                component: GameHistoryComponent
            },
            {
                path: 'statistics',
                component: StatisticsComponent
            },
            {
                path: 'friends',
                component: FriendsComponent
            },
        ]
    },
    {
        path: 'visit',
        loadChildren: () => import('./features/visits/visit.routes').then(routes => routes.visitRoutes),
        canActivateChild: [isLoggedIn, hasProfile],
        canActivate: [isLoggedIn, hasProfile],
    },
    {
        path: 'leaderboard',
        loadChildren: () => import('./features/leaderboards/leaderboards.routes').then(routes => routes.leaderBoardRoutes)
    },
    {
        path: 'games',
        loadChildren: () => import('./features/game/game.routes').then(routes => routes.gameRoutes)
    },
    {
        path: 'settings',
        component: SettingsComponent
    }
];


